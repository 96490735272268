@tailwind base;
@tailwind components;
@tailwind utilities;

@import "swiper/swiper-bundle.css";

html {
  scroll-behavior: smooth;
}

body {
  @apply font-sans;
}

@font-face {
  font-family: "Knock";
  src: url("../fonts/knock.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
